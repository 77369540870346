.wrap
  padding-top: 240px
  padding-bottom: 120px
  &_titleWrap
    display: flex
    flex-direction: column
    margin-bottom: 6px
    width: 100%
    &__box
      display: flex
      justify-content: space-between
      align-items: flex-end
      gap: 20px
      &_title
        color: #145D51
        font-family: 'Mulish', sans-serif
        font-size: 72px
        font-style: normal
        font-weight: 800
        line-height: 85%
        margin-bottom: 0
        padding-right: 11%
      &_line
        all: unset
        height: 3px
        flex: 1 0 0
        border-radius: 12px
        background: #145D51
    &__subtitle
      color: #145D51
      font-family: 'Mulish', sans-serif
      font-size: 60px
      font-style: normal
      font-weight: 800
      line-height: 85%
      margin-bottom: 0
      align-self: flex-end

  &_bottom
    display: flex
    align-items: center
    gap: 100px
    &__img
      max-width: 680px
      min-width: 100px
      width: 100%
      max-height: 470px
      min-height: 100px
      height: 100%

.right
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: 32px
  &_textWrap
    display: flex
    flex-direction: column
    gap: 20px
    margin-bottom: 0
    & span
      color: #000
      font-family: 'Mulish', sans-serif
      font-size: 18px
      font-style: normal
      font-weight: 400
      line-height: 107%
      letter-spacing: 0.18px
  &_btn
    background: #142b41
    padding: 12px 50px
    border-radius: 3px
    border: 1px solid #D0D5DD
    color: #ffffff
    font-family: 'Mulish', sans-serif
    font-size: 14px
    font-style: normal
    font-weight: 500
    line-height: 17px
    letter-spacing: 0.14px
    transition: background 1s ease, color 1s ease
    &:hover
      background: inherit
      color: #142b41

.wrap_bottom__imgWrap
  overflow: hidden
  position: relative
  width: 100%
  padding-bottom: 20px

.imageContainer
  display: flex
  transition: transform 0.4s ease-in-out
  width: 100%

.wrap_bottom__img
  min-width: 100%
  height: auto

.controls
  display: flex
  justify-content: center
  align-items: center
  margin-top: 20px
  position: absolute
  top: 91%
  left: 0
  right: 0
  transform: translateY(-50%)
  gap: 89%

.control_btn
  background: none
  border: none
  cursor: pointer
  padding: 0
  display: flex
  align-items: center
  justify-content: center

.arrow_icon
  width: 30px
  height: auto
  //filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))

.dots
  display: flex
  justify-content: center
  margin-top: 30px

.dot
  width: 10px
  height: 10px
  border-radius: 50%
  background-color: #ccc
  margin: 0 5px
  cursor: pointer

.dot.active
  background-color: #126f63

.fullscreenOverlay
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  background-color: rgba(0, 0, 0, 0.8)
  display: flex
  justify-content: center
  align-items: center
  z-index: 9999
  cursor: pointer


.fullscreenImg
  max-width: 90%
  max-height: 90%
  object-fit: contain


@media (max-width: 1440px)
  .wrap
    padding-top: 140px
    padding-bottom: 120px

  .controls
    top: 90%

@media (max-width: 1200px)
  .wrap_bottom
    flex-direction: column
    gap: 40px
    &__img
      margin-top: 50px

  .controls
    display: none


@media (max-width: 720px)
  .wrap
    padding-top: 80px
    padding-bottom: 60px
    display: flex
    flex-direction: column
    align-items: center
    &_titleWrap
      display: flex
      flex-direction: row
      justify-content: center
      gap: 10px
      margin-bottom: 50px
      &__box
        display: inline-flex
        gap: 0
        &_title
          font-size: 26px
          padding-right: 0
        &_line
          display: none
      &__subtitle
        font-size: 26px

    &_bottom
      display: flex
      flex-direction: column
      align-items: center
      gap: 40px
      width: 100%
      &__img
        height: 222px
        margin-top: 0

  .right
    align-items: stretch
    &_textWrap
      & span
        font-size: 16px
        letter-spacing: 0.16px


  .controls
    display: none

