.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
  gap: 32px;
}

.logoWrapper div {
  overflow: hidden;
  display: flex;
  width: 100%;
  z-index: -1;
}

.logo {
  width: 180px;
  height: 120px;
}

.addLogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--Light-Grey, #D0D5DD);
  padding: 27px 24px;
  cursor: pointer;
  color: var(--Light-Grey, #D0D5DD);
  text-align: center;
  font-family: Mulish, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.18px;
  gap: 8px;
  transition: all 0.7s ease-in-out;
}
.addLogo_hover {
  stroke: #D0D5DD;
  transition: all 0.7s ease-in-out;
}
.addLogo:hover {
  color: var(--Green, #145D51) !important;
  border: 1px solid var(--Light-Grey, #145D51) !important;
}
.addLogo:hover .addLogo_hover {
  stroke: #145D51;
}

.popup {
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  bottom: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 32px;
  border-radius: 10px;
  background: var(--bg-primary-notification, #FFF);
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
  z-index: 1001;
  width: 500px;
  height: 323px;
}

.popupContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  border-radius: 8px;
  position: relative;
  z-index: 1001;
  height: 100%;
  width: 100%;
}
.popupContent_title {
  color: var(--Green, #145D51);
  font-family: Mulish, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 28px;
}
.popupContent_form {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
}

.closeBtn {
  position: absolute;
  right: 32px;
  top: 27px;
  cursor: pointer;
  z-index: 1111;
  background: transparent;
  border: none;
  padding: 0;
}

.popupOverlay {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.message {
  margin: auto;
  text-align: center;
}

.submit {
  width: 230px;
  height: 41px;
  border-radius: 3px;
  border: 1px solid #D0D5DD;
  background: var(--bg-primary-notification, #FFF);
  margin: 6px auto auto auto;
  color: var(--Black-000, #000);
  font-family: Mulish, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.14px;
}

input {
  padding: 22px 0;
  color: var(--Green, #145D51);
  font-family: Mulish, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100.2%;
  border: none;
  border-bottom: 1px solid var(--Green, #145D51);
}
input:focus {
  outline: none;
}

@media (max-width: 1200px) {
  .logoWrapper div {
    width: auto;
  }
}
@media (max-width: 720px) {
  .container {
    flex-direction: column;
    gap: 50px;
  }
  .popup {
    width: 320px;
    height: 323px;
  }
}

