.bg {
  padding-bottom: 50px;
  background-image: url("../../Assets/Images/MainBlock/bgWeb.webp");
  background-size: 100% 100%;
}

.button {
  margin-top: 24px;
  padding: 12px 50px;
  text-decoration: none;
  color: var(--White, #FFF);
  font-family: Mulish, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.14px;
  border-radius: 3px;
  border: 1px solid var(--bg-primary-notification, #FFF);
  transition: color 0.5s ease, background 0.5s ease;
  cursor: pointer;
}
.button:hover {
  color: var(--Black-000, #000);
  background: #FFFFFF;
}

.wrap {
  padding-top: 162px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrap_title {
  color: #FFF;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-size: 112px;
  font-style: normal;
  font-weight: 800;
  line-height: 85%;
  margin-bottom: 24px;
}
.wrap_subtitle {
  color: #FFF;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 107%;
  letter-spacing: 0.22px;
  margin-bottom: 0;
}
.wrap_bottom {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 262px;
  text-decoration: none;
  cursor: pointer;
  animation: pulse 3s infinite ease-in-out;
}
.wrap_bottom__txt {
  color: #FFF;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@media (max-width: 1440px) {
  .bg {
    background-image: url("../../Assets/Images/MainBlock/bgMac.webp");
  }
}
@media (max-width: 1200px) {
  .bg {
    background-image: url("../../Assets/Images/MainBlock/bgTab.webp");
  }
  .wrap_title {
    font-size: 95px;
  }
  .wrap_subtitle {
    font-size: 20px;
  }
}
@media (max-width: 720px) {
  .bg {
    padding-top: 10px;
    padding-bottom: 30px;
    background-image: url("../../Assets/Images/MainBlock/bgMob.webp");
  }
  .wrap {
    padding-top: 112px;
  }
  .wrap_title {
    font-size: 52px;
  }
  .wrap_subtitle {
    font-size: 18px;
    width: 320px;
  }
  .wrap_bottom {
    margin-top: 155px;
  }
}

