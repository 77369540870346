.bg {
  background-image: url("../../Assets/Images/Form/bg.webp");
  background-size: 100% 100%;
  padding-top: 80px;
  padding-bottom: 80px;
}

.wrap {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.wrap_titleWrap {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  margin-bottom: 12px;
}
.wrap_titleWrap__title {
  color: #FFF;
  font-family: "Mulish", sans-serif;
  font-size: 72px;
  font-style: normal;
  font-weight: 800;
  line-height: 100%;
  margin-bottom: 0;
}
.wrap_titleWrap__line {
  all: unset;
  height: 3px;
  flex: 1 0 0;
  border-radius: 12px;
  background: #FFF;
}
.wrap_subtitle {
  color: #FFF;
  font-family: "Mulish", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.18px;
  margin-bottom: 0;
}

.form {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  width: 100%;
}
.form_box {
  width: 100%;
}
.form_box__wrap {
  border: none;
  background: inherit;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #FFF;
}
.form_box__wrap_inp {
  width: 100%;
  height: 100%;
  padding: 12px;
  box-sizing: border-box;
  border: none;
  outline: none;
  background: transparent;
  color: #FFF;
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100.2%;
}
.form_box__wrap_inp::placeholder {
  overflow: hidden;
  color: inherit;
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100.2%;
}
.form_btn {
  background: #ffffff;
  padding: 12px 50px;
  border-radius: 3px;
  border: 1px solid #FFF;
  color: #c94925;
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.14px;
  transition: background 1s ease, color 1s ease;
}
.form_btn:hover {
  background: #FFF;
  color: #000;
}
.form_btnSecond {
  padding: 12px 50px;
  border-radius: 3px;
  border: 1px solid #D0D5DD;
  color: #000;
  background: #FFF;
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.14px;
  transition: background 1s ease, color 1s ease;
}
.form_btnSecond:hover {
  background: #000;
  color: #FFF;
}

@media (max-width: 1440px) {
  .bg {
    background-image: url("../../Assets/Images/Form/bgMac.webp");
  }
}
@media (max-width: 1200px) {
  .bg {
    background-image: url("../../Assets/Images/Form/bgTab.webp");
  }
  .wrap_titleWrap__title {
    font-size: 56px;
  }
}
@media (max-width: 720px) {
  .bg {
    background-image: url("../../Assets/Images/Form/bgMob.webp");
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .wrap_titleWrap {
    gap: 0;
    margin-bottom: 12px;
  }
  .wrap_titleWrap__title {
    font-size: 26px;
  }
  .wrap_titleWrap__line {
    display: none;
  }
  .wrap_subtitle {
    font-size: 16px;
    letter-spacing: 0.16px;
  }
  .form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .form_box {
    width: 100%;
  }
  .form_btn {
    padding: 12px 0;
  }
  .form_btnSecond {
    padding: 12px 0;
  }
}

