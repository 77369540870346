.wrap {
  padding-top: 160px;
  padding-bottom: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}
.wrap_titleWrap {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  width: 100%;
}
.wrap_titleWrap__line {
  all: unset;
  height: 3px;
  border-radius: 12px;
  background: #145D51;
  flex: 1 0 0;
}
.wrap_titleWrap__title {
  align-self: stretch;
  color: #145D51;
  font-family: "Mulish", sans-serif;
  font-size: 72px;
  font-style: normal;
  font-weight: 800;
  line-height: 85%;
  margin-bottom: 0;
}
.wrap_boxes {
  width: 100%;
  display: grid;
  grid-template-columns: 810px 470px;
  grid-template-rows: auto auto auto;
  gap: 40px;
}

.box {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
}
.box_title {
  color: #FFF;
  font-family: "Mulish", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  margin-bottom: 4px;
}
.box_subtitle {
  color: #D0D5DD;
  font-family: "Mulish", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}
.box_btn {
  padding: 12px 50px;
  border-radius: 3px;
  border: 1px solid #D0D5DD;
  background: #FFF;
  color: #000;
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.14px;
}
.box_btn:hover {
  background: #D0D5DD;
}

.first {
  background-image: url("../../Assets/Images/Cases/cases1.webp");
  background-size: cover;
  grid-column: 1/2;
  grid-row: 1/3;
  max-width: 810px;
  height: 500px;
  flex-shrink: 0;
}

.second {
  background-image: url("../../Assets/Images/Cases/cases2.webp");
  background-size: cover;
  grid-column: 1/2;
  grid-row: 3/4;
  max-width: 810px;
  height: 380px;
  flex-shrink: 0;
}

.third {
  background-image: url("../../Assets/Images/Cases/cases3.webp");
  background-size: cover;
  grid-column: 2/3;
  grid-row: 1/2;
  max-width: 470px;
  height: 380px;
  flex-shrink: 0;
}

.fourth {
  background-image: url("../../Assets/Images/Cases/cases4.webp");
  background-size: cover;
  grid-column: 2/3;
  grid-row: 2/4;
  max-width: 470px;
  height: 500px;
  flex-shrink: 0;
}

@media (max-width: 1440px) {
  .wrap_boxes {
    grid-template-columns: auto 470px;
    gap: 30px;
  }
}
@media (max-width: 1200px) {
  .wrap {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .wrap_boxes {
    grid-template-columns: auto auto;
    gap: 20px;
  }
  .box {
    justify-content: space-between;
    padding: 24px;
  }
  .box_title {
    font-size: 22px;
  }
  .box__title {
    font-size: 26px;
  }
  .box_titleWrap {
    flex: 1 0 auto;
  }
  .box_btn {
    align-self: flex-end;
  }
}
@media (max-width: 720px) {
  .wrap {
    padding-top: 60px;
    padding-bottom: 60px;
    gap: 32px;
  }
  .wrap_titleWrap {
    align-items: center;
    justify-content: center;
    gap: 0;
  }
  .wrap_titleWrap__line {
    display: none;
  }
  .wrap_titleWrap__title {
    font-size: 26px;
  }
  .wrap_boxes {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
    justify-items: center;
    gap: 20px;
  }
  .box {
    padding: 12px;
  }
  .box_title {
    font-size: 18px;
  }
  .box_subtitle {
    font-size: 16px;
  }
  .first {
    background-image: url("../../Assets/Images/Cases/cases1_mob.webp");
    background-size: cover;
    grid-column: 1/2;
    grid-row: 1/2;
    max-width: 100%;
    height: 250px;
  }
  .second {
    background-image: url("../../Assets/Images/Cases/cases3_mob.webp");
    background-size: cover;
    grid-column: 1/2;
    grid-row: 3/4;
    max-width: 100%;
    height: 250px;
  }
  .third {
    background-image: url("../../Assets/Images/Cases/cases2_mob.webp");
    background-size: cover;
    grid-column: 1/2;
    grid-row: 2/3;
    max-width: 100%;
    height: 250px;
  }
  .fourth {
    background-image: url("../../Assets/Images/Cases/cases4_mob.webp");
    background-size: cover;
    grid-column: 1/2;
    grid-row: 4/5;
    max-width: 100%;
    height: 250px;
  }
}

