.container
  display: flex
  align-items: center
  justify-content: space-between
  transition: background-color 0.5s ease
  padding-left: 10%
  padding-right: 10%
  padding-top: 20px
  padding-bottom: 20px
  &.scrolled
    background-color: #116F63
    position: fixed
    top: 0
    left: 0
    right: 0
    z-index: 1 /* Зробіть його поверх інших елементів */

  &_logo
    width: 138px
    height: auto
    cursor: pointer
  &_links
    display: flex
    align-items: center
    gap: 24px
    &__link
      color: #FFF
      font-family: 'Mulish', sans-serif
      font-size: 16px
      font-style: normal
      font-weight: 600
      line-height: normal
      cursor: pointer
      text-decoration: none
      position: relative
      &::after
        opacity: 0
        content: ''
        position: absolute
        top: 0
        left: 50%
        transform: translateY(100%) translateX(-50%) scaleX(0)
        width: 110%
        height: 15px
        border-bottom: 1px solid #FFF
        background: linear-gradient(180deg, rgba(70, 15, 94, 0.00) 0%, rgba(33, 215, 237, 0.29) 100%)
        transition: opacity 0.3s ease, transform 0.3s ease
      &:hover
        &::after
          opacity: 1
          transform: translateY(100%) translateX(-50%) scaleX(100%)
  &_btn
    padding: 12px 50px
    border-radius: 3px
    background: #C94925
    border: none
    color: #FFF
    font-family: 'Mulish', sans-serif
    font-size: 14px
    font-style: normal
    font-weight: 500
    line-height: 17px
    letter-spacing: 0.14px
    transition: background 1s ease, color 1s ease


.wrap
  display: flex
  align-items: center
  gap: 20px


@media (max-width: 1200px)
  .container_btn
    padding: 12px 20px
  .container_logo
    width: 130px



@media (max-width: 720px)
  .container
    display: none
