.whysab_block
  padding-top: 120px
  padding-bottom: 240px

.why_title_block
  display: flex
  flex-direction: row
  justify-content: space-between
  gap: 10px
  align-items: flex-end
  
.why_title
  color: var(--Green, #145D51)
  font-family: Mulish, sans-serif
  font-size: 72px
  font-style: normal
  font-weight: 800
  line-height: 85%
  margin-bottom: 10px

.why_title_span
  color: var(--Green, #145D51)
  font-family: Mulish, sans-serif
  font-style: normal
  font-weight: 800
  line-height: 85%
  font-size: 60px
  padding-left: 70px

.why_title_line
  height: 3px
  width: 53%
  border-radius: 12px
  background: var(--Green, #145D51)
  margin-bottom: 10px

.why_content_block
  display: flex
  flex-direction: row
  justify-content: space-between
  gap: 125px
  margin-top: 49px

.why_content_left
  display: flex
  flex-direction: column
  justify-content: space-between
  max-width: 600px

.why_content_left_text
  color: var(--Black-000, #000)
  font-family: Mulish, sans-serif
  font-size: 18px
  font-style: normal
  font-weight: 400
  line-height: 107%
  letter-spacing: 0.18px
  &__bold
    font-weight: 600

.why_video_img
  width: 100%
  max-width: 500px
  height: auto

.why_btn
  max-width: 242px
  padding: 12px 50px
  border-radius: 3px
  border: 1px solid #D0D5DD
  background: var(--bg-primary-notification, #FFF)
  color: #000
  font-family: Mulish, sans-serif
  font-size: 14px
  font-style: normal
  font-weight: 500
  line-height: 17px
  letter-spacing: 0.14px
  transition: background 0.7s ease, color 0.7s ease, border 0.7s ease
  margin-top: 32px

.why_btn:hover
  color: var(--White, #FFF)
  border: 1px solid var(--Black-000, #000)
  background: var(--Black-000, #000)

.why_cards_block
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: center
  gap: 20px
  margin-top: 70px

.why_card
  display: flex
  width: 315px
  //max-height: 120px
  padding: 24px
  flex-direction: column
  justify-content: center
  align-items: center
  gap: 24px
  border-radius: 3px
  border: 1px solid var(--Green, #145D51)
  background: var(--bg-primary-notification, #FFF)

.why_card_top
  display: flex
  flex-direction: row
  gap: 8px
  align-items: center
  
.why_card_img
  width: auto
  height: 32px

.why_card_top_title
  color: #000
  font-family: Mulish, sans-serif
  font-size: 18px
  font-style: normal
  font-weight: 600
  line-height: 107%
  letter-spacing: 0.18px
  margin: 0

.why_card_bottom_text
  color: var(--Black-000, #000)
  text-align: center
  font-family: Mulish, sans-serif
  font-size: 18px
  font-style: normal
  font-weight: 400
  line-height: 107%
  letter-spacing: 0.18px
  margin: 0

@media (max-width: 1440px)
  .why_title_line
    width: 46%

  .why_content_block
    gap: 85px

  .why_content_left
    max-width: 500px

  .why_cards_block
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: space-evenly

@media (max-width: 1200px)

  .whysab_block
    padding-top: 80px
    padding-bottom: 140px
  
  .why_content_block
    flex-direction: column
    gap: 50px
    align-items: center
    margin-top: 49px

  .why_content_left
    max-width: 100%

  .why_video_img
    width: 100%
    max-width: 100%

  .why_title_line
    display: none

  .why_title_block
    justify-content: center

  .why_title
    font-size: 50px
    margin-bottom: 10px

  .why_title_span
    font-size: 25px
    padding-left: 0
    text-align: center

@media (max-width: 720px)
  .why_title_block
    display: flex
    flex-direction: row
    justify-content: center
    gap: 10px
    align-items: flex-end

  .why_title
    font-family: Mulish, sans-serif
    font-size: 26px
    margin-bottom: 10px

  .why_title_span
    font-size: 25px
    padding-left: 0
    text-align: center

  .why_title_line
    display: none

  .why_btn
    width: 100%
    max-width: 100%
    margin-top: 32px

  .whysab_block
    padding-top: 60px
    padding-bottom: 80px

  .why_content_left_text
    font-size: 16px

  .why_card
    display: flex
    width: 100%

