.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.5s ease;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.container.scrolled {
  background-color: #116F63;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.container_logo {
  width: 119px;
  cursor: pointer;
}
.container_burgerIcon {
  width: 35px;
  height: 29px;
}

.burger {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  transform-origin: top;
  z-index: 101;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}
.burger_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 44px;
}
.burger_top__txt {
  color: #FFF;
  font-family: "Mulish", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.burger_top__btn {
  padding: 8px;
}
.burger_middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.burger_middle__link {
  color: #FFF;
  font-family: "Mulish", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  padding-bottom: 21px;
  padding-top: 20px;
  border-bottom: 1px solid #F2F4F7;
  text-decoration: none;
  width: 100%;
}
.burger_middle__btn {
  margin-top: 40px;
  padding: 12px 50px;
  border-radius: 3px;
  background: #C94925;
  border: none;
  color: #FFF;
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.14px;
  transition: background 1s ease, color 1s ease;
}

@media (min-width: 721px) {
  .container {
    display: none;
  }
  .back {
    display: none;
  }
}

