.sabinvest_block
  padding: 160px 0

.sabinvest_content
  display: flex
  flex-direction: row
  gap: 70px
  align-items: center
  margin-top: 50px

.sabinvest_content_left
  max-width: 650px
  display: flex
  flex-direction: column
  gap: 24px

.sabinvest_content_text
  color: var(--Black-000, #000)
  font-family: Mulish, sans-serif
  font-size: 18px
  font-style: normal
  font-weight: 400
  line-height: 100%
  letter-spacing: 0.18px
  margin: 0

.sabinvest_content_img
  width: 100%
  height: auto
  max-width: 600px

@media (max-width: 1440px)
  .sabinvest_content_left
    max-width: 550px

@media (max-width: 1200px)
  .sabinvest_block
    padding: 100px 0

  .sabinvest_content
    display: flex
    flex-direction: column

@media (max-width: 1200px)
  .sabinvest_block
    padding: 60px 0

  .sabinvest_content_left
    max-width: 100%

  .sabinvest_content_img
    width: 100%
    height: auto
    max-width: 100%

  .sabinvest_content_text
    font-size: 16px
    letter-spacing: 0.16px

  .sabinvest_content
    gap: 40px

