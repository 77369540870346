.footer {
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.footer_wrap {
  display: flex;
  gap: 35px;
  align-items: self-start;
  justify-content: center;
}

.phone {
  text-align: center;
  text-decoration: none;
  color: var(--Black-000, #000);
  font-family: Mulish, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.logo {
  width: 150px;
  height: 80px;
}

.navLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}
.navLinks a {
  position: relative;
  text-decoration: none;
  color: var(--Black-000, #000);
  font-family: Mulish, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}
.navLinks a::after {
  opacity: 0;
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(100%) translateX(-50%) scaleX(0);
  width: 110%;
  height: 15px;
  border-bottom: 1px solid #000;
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.navLinks a:hover::after {
  opacity: 1;
  transform: translateY(100%) translateX(-50%) scaleX(100%);
}

.socialIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: 5px;
}
.socialIcons a {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-decoration: none;
  gap: 5px;
}
.socialIcons a p {
  margin: 0;
  opacity: 0;
  max-width: 0;
  transition: opacity 1s ease-in-out, max-width 1s ease-in-out, margin 1s ease-in-out;
  color: var(--Black-000, #000);
  font-family: Mulish, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.socialIcons a:hover p {
  text-decoration: none;
  opacity: 1;
  max-width: 150px;
}

.iconLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}

@media (max-width: 720px) {
  .footer_wrap {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  .logo {
    order: -1;
  }
  .navLinks {
    flex-direction: column;
    gap: 16px;
  }
}

