.footer 
  background-color: #f0f0f0
  padding: 24px 0
  display: flex
  justify-content: space-between
  align-items: center
  font-family: Arial, sans-serif
  font-size: 14px
  color: #000


.footerContent
  max-width: 1200px
  width: 100%
  margin: 0 auto
  display: flex
  justify-content: space-evenly
  align-items: center
  padding: 0 20px


.copyright
  color: var(--Black-000, #000)
  font-family: Mulish, sans-serif
  font-size: 14px
  font-style: normal
  font-weight: 600
  line-height: 24px /* 171.429% */
  letter-spacing: 0.2px

.developer
  display: flex
  align-items: center
  color: var(--Black-000, #000)
  font-family: Mulish, sans-serif
  font-size: 14px
  font-style: normal
  font-weight: 600
  line-height: 24px /* 171.429% */
  letter-spacing: 0.2px
  svg
    margin: 0 2px 0 0

.agencyLink
  display: flex
  align-items: center
  text-decoration: none
  color: #000
  margin-left: 3px


.agencyLogo
  width: 18px
  height: 18px
  margin-right: 5px

@media (max-width: 720px)
  .footerContent
    flex-direction: column
