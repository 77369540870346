.sab_kreativ_block {
  background-image: url("../../Assets/Images/SabKreativ/sab_x_creativ_bg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 60px 0;
}

.sab_kreativ_content {
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  align-items: center;
}

.sab_kreativ_logos_block {
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items: center;
  justify-content: center;
}

.sab_kreativ_sablogo {
  width: 206px;
  height: auto;
}

.sab_kreativ_xlogo {
  width: 30px;
  height: auto;
}

.sab_kreativ_kreativlogo {
  width: 110px;
  height: auto;
}

.sab_kreativ_text {
  color: var(--White, #FFF);
  text-align: center;
  font-family: Mulish, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 107%;
  letter-spacing: 0.18px;
  max-width: 600px;
}

@media (max-width: 720px) {
  .sab_kreativ_block {
    background-image: url("../../Assets/Images/SabKreativ/sab_kreativ_bg_mobile.webp");
  }
  .sab_kreativ_sablogo {
    width: 147px;
    height: auto;
  }
  .sab_kreativ_xlogo {
    width: 21px;
    height: auto;
  }
  .sab_kreativ_kreativlogo {
    width: 79px;
    height: auto;
  }
  .sab_kreativ_logos_block {
    gap: 35px;
  }
  .sab_kreativ_text {
    font-size: 16px;
    letter-spacing: 0.16px;
  }
}

